export default {
    home: {
        language: "Cambio de lenguaje",
        banner_p1: "Comercio de divisas, bienes, índices, acciones a través de plataformas",
        banner_p2: "",
        banner_msg1: "Y más productos financieros populares",
        banner_msg2: "",
        banner_msg3: "",
        banner_link1: "Registrarse de inmediato y comenzar a usar",
        banner_link2: "Registe-se Agora",
        swiper_h1: "Alto rendimiento",
        swiper_h2: "Motor de emparejamiento",
        swiper_h3: "Nivel financiero",
        swiper_h4: "Protección de Seguridad",
        swiper_h5: "Productos",
        swiper_h6: "Rico y diverso",
        swiper_h7: "Globalización",
        swiper_h8: "Diseño",
    },
    list: {
        title: "Destaque",
        th1: "Spot",
        th2: "Último preço",
        th3: "Mudança 24H",
        th4: "Mercados",
        th5: "Vá Negocia",
    },
    info1: {
        tit1: "Produtos Principais da Plataforma",
        tit2: "StartTraderos Spot",
        p1: "O mecanismo robusto corresponde transações na 3ª geração",
        p2: "Nosso motor corresponde aos pedidos de terceira geração, garantindo que todas as operações principais sejam concluídas dentro de um décimo de segundo",
        p3: "A função pesquisada da negociações desenvolvidas na forma independente",
        p4: "StartTrade oferece funções exclusivas: opções na posição de negociação rápida, divisão e fusão nas posições, lucro de vários e stop- loss, liquidação de preço duplo, parado de fuga e mais funcionalidade que permite aos comerciantes tomar decisões de investimento mais inteligentes,",
        p5: "Fornece mecanismos de negociação flexíveis e uma variedade de opções de alavancagem para atender ao apetite de risco dos traders em todos os níveis,",
        p6: "Fornece mecanismos de negociação flexíveis e uma variedade de opções de alavancagem para atender ao apetite de risco dos traders em todos os níveis,",
        btn: "Comece a negociar"
    },
    info2: {
        tit1: "Função de transação um",
        tit2: "Negociação Rápida no Gráfico",
        p1: "Negociação no Gráfico",
        p2: "Negociação Rápida no Gráfico é a inovação exclusiva da StartTrade que lhe dá a opção de colocar negociações e editá-las diretamente no gráfico sem alternar entre a página de ordens",
        p3: "Gestão de Negociação geral",
        p4: "Com esta poderosa função de negociação, você pode concluir várias operações de negociação no Gráfico de forma intuitiva e eficiente, incluindo definir alavancagem, definir preços de TP/SL e ajustar suas ordens,",
    },
    info3: {
        tit1: "Função de transação dois",
        tit2: "Combinar e Separar as posições",
        p1: "Divida livremente várias posições",
        p2: "Os usuários podem manter várias posições com diferentes alavancagens na mesma moeda, Definir uma posição separada permite que você escolha e ajuste de forma independente a alavancagem em vários pontos de entrada, Você pode adicionar margem à posição e até mesmo combinar as posições separadas à vontade ,Short ou long até 20 posições divididas diferentes em cada moeda!",
        p3: "Reduzir seu risco",
        p4: "Posições múltiplas de diferentes alavancagens podem ser mantidas na mesma moeda, cada uma das quais pode ser ajustada independentemente,",
    },
    info4: {
        tit1: "Função de transação três",
        tit2: "Vários Tipos de Stop-Gain e Stop-Loss",
        p1: "Três funções exclusivas de Stop-Gain e Stop-Loss",
        p2: "StartTrade fornece três modos de Stop de Ganhos e Stop de Perdas (SG/SP) para controlar seu risco e gerenciar lucros de forma eficaz, Você pode 1) Definir um (SG/SP) para o valor total da posição, 2) Definir um (SG/SP) com um valor parcial da margem da posição, ou 3) Definir um (SG/SP) condicional que permite definir um preço de disparo que aciona o fechamento da posição a um preço específico,",
        p3: "Até 50 ordens de Stop de Ganhos/Perdas ao mesmo tempo",
        p4: "Você pode definir Stop de Ganhos/Perdas em posições e ordens pendentes a qualquer momento para controlar o risco de forma flexível; ao mesmo tempo, suporta a definição de até 50 ordens Stop de Ganhos/Perdas para a mesma posição com diferentes números de contratos e preços, incluindo ordens de Limite ou ordens de Mercado, para atender diferentes estratégias de investimento,",
    },
    info5: {
        p1: "Operação estável por 3 anos, sem tempo de inatividade e atraso",
        p2: "Recursos de mídia da indústria",
        p3: "Principais cooperações KOL",
        p4: "A escolha de milhões clientes",
    },
    why: {
        p1: "Por que escolher StartTrade",
        p2: "Recursos de P&D fortes e independentes",
        p3: "Sistema de negociação de terceira geração auto-desenvolvido de 0 a 1",
        p4: "Recursos exclusivos para lhe dar a melhor experiência de negociação sem falhas",
        p5: "Top Seguro",
        p6: "Estrutura de carteira quente e fria garante ativos em",
        p7: "métodos isolados,garantir a segurança de seus ativos",
        p8: "Sistemas de controle de risco fortificados",
        p9: "Mecanismo de proteção de liquidação de preço duplo para proteger os",
        p10: "comerciantes altamente alavancados de incorrer em perdas",
        p11: "Equipe Inovadora Global",
        p12: "Especialistas em Blockchain & Tecnologia  ",
        p13: "Equipe culturalmente diversificada",
        p14: "Suporte Multilíngue 24/7",
        p15: "Resposta de um minuto às suas necessidades,",
        p16: "",
    },
    info6: {
        p1: "Você está pronto para iniciar sua jornada de criptos?",
        p2: "Comece seu investimento em criptomoeda agora",
    },
    foot: {
        download: "download"
    },
    download: {
        h1: "Por favor, utilice su teléfono móvil para escanear el código QR para descargar",
        copy: "Copiar",
        down: "descargar",
        rate: "valoración",
        age: "edad",
        complain: "aplicación",
        tit1: "Nuevas características",
        version: "Versión",
        p1: "Esta versión incluye correcciones de errores y mejoras de rendimiento",
        tit2: "Introducción a la aplicación",
        p2: "¡Bienvenido a IDEX Global, una de las principales bolsas de activos digitales del mundo! Comercia con más de 1,100 criptomonedas, incluyendo Bitcoin (BTC), Ether (ETH), Dogcoin (DOGE), MX Token (MX) y más, Como la bolsa de criptodivisas líder en el mundo, StartTrade es conocida por ser la primera en lanzar proyectos y monedas modales de alta calidad, StartTrade Global proporciona servicios profesionales y seguros de negociación y gestión de activos digitales en los que confían más de 7 millones de usuarios de todo el mundo,",
        tit3: "Reseñas y valoraciones",
        p3: "sobre 5",
        tit4: "Información",
        label1: "Vendedores",
        label2: "Tamaño",
        label3: "Compatibilidad",
        label4: "Idiomas",
        value1: "Chino e inglés",
        label5: "edad (de una persona)",
        value2: "Sólo mayores de 18 años",
        label6: "Precio",
        value3: "freeware",
        tit5: "Política de privacidad",
        p5: "Descargo de responsabilidad: Este sitio web sólo aloja la descarga e instalación de la App para el desarrollador, el contenido y cuestiones relacionadas con el funcionamiento dentro de la App son responsabilidad del desarrollador de la App y no tienen nada que ver con este sitio web,"
    }
};
