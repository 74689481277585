export default {
    home: {
        language: "Mudança de idioma",
        banner_p1: "Negociar câmbio, commodities, índices, ações através da plataforma",
        banner_p2: "",
        banner_msg1: "E produtos financeiros mais populares",
        banner_msg2: "",
        banner_msg3: "",
        banner_link1: "Registar-se e começar a usar agora",
        banner_link2: "Registe-se Agora",
        swiper_h1: "Alto desempenho",
        swiper_h2: "Motor de correspondência",
        swiper_h3: "Nível financeiro",
        swiper_h4: "Protecção da segurança",
        swiper_h5: "Produto",
        swiper_h6: "Rico e diversificado",
        swiper_h7: "Globalização",
        swiper_h8: "Layout",
    },

    list: {
        title: "Popular",
        th1: "Mancha",
        th2: "Preço mais recente",
        th3: "Flutuação de 24 horas",
        th4: "Mercado",
        th5: "Para negociar",
    },
    info1: {
        tit1: "Produtos principais da plataforma",
        tit2: "Contrato à vista permanente",
        p1: "Motor de matchmaking de terceira geração",
        p2: "Um poderoso sistema de matchmaking garante que todas as operações principais dos usuários sejam concluídas em 100 milissegundos",
        p3: "Função de transacção característica desenvolvida independentemente",
        p4: "Funções de negociação em destaque, como ordenação offline, super consolidação, stop loss diversificado e stop loss, nivelamento forte de preço duplo e reversão de um clique, ajudando os traders a tomar melhores decisões",
        p5: "Estratégias de negociação flexíveis",
        p6: "Fornecer várias opções de alavancagem para atender às preferências de risco dos traders em todos os níveis",
        btn: "Iniciar a negociação"
    },
    info2: {
        tit1: "Transacção Função 1",
        tit2: "Colocar um pedido na linha K",
        p1: "Faça pedidos rapidamente com um clique",
        p2: "A negociação K-line é um conjunto completo de experiências funcionais inovadoras lançadas exclusivamente pela StartTrade em toda a rede.Com base na poderosa função de negociação K-line, as operações de negociação podem ser realizadas diretamente na página K-line sem a necessidade de alternar entre a página do mercado e a página do pedido",
        p3: "Gestão global das transacções",
        p4: "Os usuários podem concluir facilmente e eficientemente várias operações de transação na linha K, incluindo operações de armazém e operações de pedidos. Permita que os usuários façam pedidos rapidamente e façam ajustes precisos a qualquer momento quando o mercado mudar rapidamente",
    },
    info3: {
        tit1: "Transacção Função 2",
        tit2: "Divisão de super armazém",
        p1: "Comutação livre de múltiplas posições",
        p2: "Os usuários podem manter uma posição combinada em direções longas e curtas na mesma moeda, bem como múltiplas sub posições com N alavancas diferentes, e suportar fusão e comutação livres, tornando conveniente para os usuários formular estratégias de negociação de forma flexível",
        p3: "Posições independentes reduzem o risco",
        p4: "Uma moeda pode manter várias posições com diferentes níveis de alavancagem, e cada sub-posição pode ajustar independentemente alavancagem, aumentar ou diminuir posições e definir stop profit e stop loss. Além disso, as sub-posições podem ser mescladas sem qualquer perda, ajudando os usuários a reduzir o impacto do lucro e perda geral da posição",
    },
    info4: {
        tit1: "Função de Transacção Três",
        tit2: "Stop Profit e Stop Loss diversificados",
        p1: "Três funções principais de paragem de lucros e perdas",
        p2: "A StartTrade fornece três modos: stop loss de posição total, stop loss de posição parcial e stop loss de preço para ajudar os usuários a controlar os riscos de negociação",
        p3: "Até 50 posições diferentes de stop loss e stop loss",
        p4: "Os usuários podem definir pontos de stop profit e stop loss a qualquer momento em suas posições e ordens, controlando de forma flexível os riscos; Ao mesmo tempo, suporta a criação de até 50 ordens stop loss, incluindo ordens limite ou ordens de mercado, para diferentes folhas e preços na mesma posição, para atender diferentes estratégias de investimento dos usuários",
    },
    info5: {
        p1: "Operação estável por 3 anos sem tempo de inatividade ou atraso",
        p2: "Apoio aos canais de comunicação social da indústria",
        p3: "Colaboração responsável pela KOL",
        p4: "A escolha de milhões de utilizadores",
    },
    why: {
        p1: "Porquê escolher IDEX",
        p2: "Fortes capacidades independentes de investigação e desenvolvimento",
        p3: "Desenvolvimento independente do sistema de negociação de terceira geração de 0 a 1",
        p4: "Criando uma experiência de negociação definitiva com excelente desempenho",
        p5: "Segurança de alto nível",
        p6: "Controle de risco para isolamento de carteiras quentes e frias",
        p7: "Medidas duplas para garantir a segurança de seus ativos",
        p8: "Um sistema abrangente de controlo dos riscos",
        p9: "Duplo preço forte mecanismo de proteção de equalização",
        p10: "Reduzir o risco de exposição do utilizador",
        p11: "Disposição global",
        p12: "Encontro Global de Elite",
        p13: "Distribuição global da equipa",
        p14: "7 * Serviço 24 horas",
        p15: "Mecanismo de resposta de 1 minuto",
        p16: "Serviço 24/7 para utilizadores globais",
    },
    info6: {
        p1: "Começar a negociar moeda digital?",
        p2: "Comece seu investimento em criptomoedas agora",
    },
    foot: {
        download: "download",
        copy_success: "Copiado com sucesso",
        copy_error: "a cópia falhou",
    },
    download: {
        h1: "请使用手机扫描二维码下载",
        copy: "复制",
        down: "下载",
        rate: "评分",
        age: "年龄",
        complain: "应用投诉",
        tit1: "新功能",
        version: "版本",
        p1: "此版本包括错误修复和性能改进",
        tit2: "应用介绍",
        p2: "欢迎来到 IDEX Global，世界领先的数字资产交易所之一！交易超过1100种加密货币，包括比特币(BTC)、以太坊(ETH)、狗狗币(DOGE)、MX Token(MX)等。作为世界领先的加密货币交易所，StartTrade以率先推出优质项目和模因币而闻名, StartTrade Global 提供专业、安全的数字资产交易和管理服务，受到全球超过 700 万用户的信赖。",
        tit3: "评论及评分",
        p3: "满分5分",
        tit4: "信息",
        label1: "供应商",
        label2: "大小",
        label3: "兼容性",
        label4: "语言",
        value1: "中文和英文",
        label5: "年龄",
        value2: "限18岁以上",
        label6: "价格",
        value3: "免费",
        tit5: "隐私政策",
        p5: "免责声明: 本网站仅为开发者提供App的下载和安装托管，App内的内容和运营相关事项由App开发者负责，与本网站无关。"
    }
};
