export default {
    home: {
        language: "Pertukaran bahasa",
        banner_p1: "Penjualan pertukaran asing, komoditas, indeks, saham melalui platform",
        banner_p2: "",
        banner_msg1: "Dan produk keuangan yang lebih populer",
        banner_msg2: "",
        banner_msg3: "",
        banner_link1: "Daftar dan mulai trading di platform sekarang juga",
        banner_link2: "Daftar dan mulai",
        swiper_h1: "Performasi tinggi",
        swiper_h2: "Mesin yang cocok",
        swiper_h3: "Tingkat keuangan",
        swiper_h4: "perlindungan keselamatan",
        swiper_h5: "produk",
        swiper_h6: "Rich and diverse",
        swiper_h7: "globalisasi",
        swiper_h8: "layout",
    },
    list: {
        title: "Hot",
        th1: "Titik",
        th2: "Harga terakhir",
        th3: "Peningkatan 1 Jam",
        th4: "pasar",
        th5: "Pergi Berdagang",
    },
    info1: {
        tit1: "Fungsi Utama Platform",
        tit2: "Kontrak Abadi Titik",
        p1: "Mesin pencocokan generasi ketiga tugas berat",
        p2: "Sistem generasi ketiga yang kuat memastikan bahwa semua operasi pengguna selesai dalam 10 detik,",
        p3: "Penelitian independen dan pengembangan fitur perdagangan unik",
        p4: "K-Line Speed Trading, TP/SL Multipoint Functionality, Trailing Stop, Merge and Split Position Mode dan fitur unik lainnya akan membantu trader melakukan trading lebih cepat dan efisien,",
        p5: "Fleksibilitas maksimum untuk perdagangan",
        p6: "StartTrade menyediakan berbagai opsi leverage untuk pedagang dari semua level",
        btn: "mulai berdagang"
    },
    info2: {
        tit1: "Fungsi transaksi satu",
        tit2: "K-Line Perdagangan Cepat",
        p1: "Menempatkan pesanan dengan satu klik",
        p2: "K-Line Speed Trading adalah serangkaian fitur inovatif yang dirilis secara eksklusif di StartTrade, Berdasarkan fungsi perdagangan K-Line yang kuat, Anda dapat melakukan berbagai operasi perdagangan dengan pesanan pada grafik K-Line dengan cara yang sangat intuitif dan efisien,",
        p3: "Manajemen Perdagangan yang Komprehensif",
        p4: "Berkat fitur K-Line, Anda tidak perlu lagi bernavigasi dari satu halaman ke halaman lain untuk melakukan pemesanan, Dengan perubahan pasar yang cepat dan tidak terduga, Anda dapat dengan cepat menempatkan (mengubah) pesanan atau melakukan penyesuaian kapan saja, tepat di grafik!",
    },
    info3: {
        tit1: "Fungsi transaksi dua",
        tit2: "Pisahkan dan gabungkan mode posisi",
        p1: "Perubahan gratis dari beberapa posisi secara bersamaan",
        p2: "Pengguna dapat secara bersamaan memegang posisi Long dan Short dalam mata uang yang sama sambil memegang beberapa sub-posisi dengan tingkat leverage yang berbeda untuk membantu pedagang mengembangkan strategi perdagangan yang fleksibel,",
        p3: "Risiko rendah untuk posisi independen",
        p4: "Di platform kami, pedagang dapat memegang beberapa posisi dalam mata uang yang sama, dengan tingkat leverage yang berbeda, Di setiap sub-posisi, Anda dapat secara mandiri menyesuaikan tingkat leverage, menambah atau mengurangi posisi, mengatur TP dan SL, dan menggabungkan sub-posisi tanpa kerugian,",
    },
    info4: {
        tit1: "Fungsi transaksi tiga",
        tit2: "Alat TP dan SL yang luas",
        p1: "Fungsionalitas TP/SL multipoint",
        p2: "Pengguna StartTrade dapat menerapkan berbagai strategi perdagangan mereka kapan saja dengan mengatur TP dan SL pada pesanan yang sudah ditempatkan, untuk manajemen risiko yang fleksibel, sementara itu didukung untuk mengatur hingga 50 TP dan SL pada posisi yang sama, tetapi dengan cont dan harga yang berbeda, termasuk limit dan market order,",
        p3: "Hingga 50 level TP/SL yang berbeda",
        p4: "StartTrade memiliki fungsi TP/SL yang kuat yang memungkinkan Anda menutup perdagangan di beberapa titik harga dalam satu posisi, Jika Anda tidak yakin ke arah mana pasar akan mengambil, Anda dapat mengontrol risiko dengan menutup setengah dari posisi Anda untuk mendapatkan keuntungan dan membiarkan setengah lainnya terbuka, Lindungi aset Anda dari fluktuasi pasar cryptocurrency dan likuidasi paksa dengan fitur hebat ini,",
    },
    info5: {
        p1: "Lebih dari tiga tahun kerja yang stabil",
        p2: "Tidak ada crash atau macet",
        p3: "KOL bekerja sama dengan kami",
        p4: "Pengguna memilih platform kami",
    },
    why: {
        p1: "Mengapa memilih StartTrade?",
        p2: "Penelitian dan Pengembangan Independen",
        p3: "Sistem generasi ketiga",
        p4: "Perdagangan ultra-efisien dengan kinerja luar biasa",
        p5: "Keamanan Tingkat Tinggi",
        p6: "Manajemen risiko dompet Panas dan Dingin",
        p7: "Jaminan Ganda untuk Melindungi Aset Anda",
        p8: "Sistem Pengendalian Risiko yang Sempurna",
        p9: "Mekanisme Perlindungan Likuidasi Ganda",
        p10: "Mengurangi risiko likuidasi",
        p11: "tim profesional",
        p12: "Pakar di bidangnya",
        p13: "Tim kami tersebar di seluruh dunia",
        p14: "Meja Bantuan Multibahasa 24/7",
        p15: "Dukungan cepat dan efisien",
        p16: "Dukungan pelanggan 24/7 untuk pengguna di seluruh dunia",
    },
    info6: {
        p1: "Mari kita mulai perdagangan cryptocurrency sekarang?",
        p2: "Mulai investasi cryptocurrency Anda sekarang",
    },
    foot: {
        download: "unduh"
    },
    download: {
        h1: "Silakan gunakan ponsel Anda untuk memindai kode QR untuk mengunduh",
        copy: "Salin",
        down: "mengunduh",
        rate: "Peringkat",
        usia: "usia",
        komplain: "Keluhan Aplikasi",
        tit1: "Fitur Baru",
        version: "Versi",
        p1: "Versi ini mencakup perbaikan bug dan peningkatan kinerja",
        tit2: "Pengenalan aplikasi",
        p2: "Selamat datang di IDEX Global, salah satu bursa aset digital terkemuka di dunia! Perdagangkan lebih dari 1,100 mata uang kripto termasuk Bitcoin (BTC), Ether (ETH), Dogcoin (DOGE), MX Token (MX), dan banyak lagi, Sebagai bursa mata uang kripto terkemuka di dunia, StartTrade dikenal sebagai yang pertama meluncurkan proyek berkualitas tinggi dan koin modal, StartTrade Global menyediakan layanan perdagangan dan manajemen aset digital yang profesional dan aman yang dipercaya oleh lebih dari 7 juta pengguna di seluruh dunia,",
        tit3: "Ulasan & Peringkat",
        p3: "dari 5",
        tit4: "Informasi",
        label1: "Vendor",
        label2: "Ukuran",
        label3: "Kompatibilitas",
        label4: "Bahasa",
        value1: "Bahasa Mandarin dan Inggris",
        label5: "Usia (seseorang)",
        value2: "Hanya untuk usia di atas 18 tahun",
        label6: "Harga",
        value3: "freeware",
        tit5: "Kebijakan Privasi",
        p5: "Penafian: Situs web ini hanya menjadi tempat pengunduhan dan penginstalan Aplikasi untuk pengembang, konten dan hal-hal yang berkaitan dengan operasi dalam Aplikasi adalah tanggung jawab pengembang Aplikasi dan tidak ada hubungannya dengan situs web ini,"
    }
};
