export default {
    home: {
        language: "Chuyển đổi ngôn ngữ",
        banner_p1: "Giao dịch ngoại hối, hàng hóa, chỉ số, cổ phiếu thông qua nền tảng",
        banner_p2: "",
        banner_msg1: "Và nhiều sản phẩm tài chính nóng hơn.",
        banner_msg2: "",
        banner_msg3: "",
        banner_link1: "Đăng ký ngay và bắt đầu sử dụng",
        banner_link2: "Đăng ký ngay",
        swiper_h1: "Hiệu suất cao",
        swiper_h2: "Động cơ phù hợp",
        swiper_h3: "Cấp tài chính",
        swiper_h4: "Bảo vệ an toàn",
        swiper_h5: "Sản phẩm",
        swiper_h6: "Giàu và đa dạng",
        swiper_h7: "Toàn cầu hóa",
        swiper_h8: "Bố trí",
    },
    list: {
        title: "Nóng bỏng",
        th1: "Hàng tồn kho",
        th2: "Giá mới nhất",
        th3: "24h giảm",
        th4: "Giá thị trường",
        th5: "Giao dịch",
    },
    info1: {
        tit1: "Sản phẩm cốt lõi của nền tảng",
        tit2: "Hợp đồng vĩnh viễn tại chỗ",
        p1: "Động cơ fusion thế hệ thứ ba",
        p2: "Hệ thống ghép mạnh mẽ đảm bảo tất cả các hoạt động cốt lõi của người dùng được hoàn thành trong 100 mili giây",
        p3: "Tự phát triển chức năng giao dịch đặc trưng",
        p4: "Các tính năng giao dịch đặc trưng như lệnh ngoại tuyến K, lệnh Super Ho, lệnh dừng thu nhập đa dạng, mức giá mạnh gấp đôi, đảo chiều một cú nhấp chuột, v.v. giúp nhà giao dịch đưa ra quyết định tốt hơn",
        p5: "Chiến lược giao dịch linh hoạt",
        p6: "Cung cấp nhiều lựa chọn đòn bẩy phù hợp với sở thích rủi ro của nhà giao dịch ở mọi cấp độ",
        btn: "Bắt đầu giao dịch"
    },
    info2: {
        tit1: "Chức năng giao dịch 1",
        tit2: "Đơn hàng dưới dòng K",
        p1: "Một phím cực nhanh đặt hàng",
        p2: "K-Line Trading là một trải nghiệm đầy đủ các tính năng sáng tạo độc quyền trên toàn mạng của StartTrade, dựa trên các tính năng giao dịch K-Line mạnh mẽ, hoạt động giao dịch trực tiếp trên trang K-Line mà không cần chuyển đổi qua lại giữa các trang báo giá và các trang tiếp theo.",
        p3: "Quản lý giao dịch toàn diện",
        p4: "Bạn có thể hoàn thành các thao tác giao dịch khác nhau trên đường K một cách trực quan và hiệu quả, bao gồm: thao tác vị trí, thao tác đặt hàng. Để người dùng có thể đặt hàng nhanh chóng, điều chỉnh chính xác bất cứ lúc nào khi giá thị trường thay đổi nhanh chóng.",
    },
    info3: {
        tit1: "Chức năng giao dịch II",
        tit2: "Siêu chia tách",
        p1: "Nhiều vị trí chuyển đổi miễn phí",
        p2: "Người dùng có thể đồng thời giữ các vị trí đóng theo cả hai hướng trong cùng một loại tiền tệ, đồng thời có thể giữ nhiều vị trí phụ với N đòn bẩy khác nhau và hỗ trợ chuyển đổi hợp nhất tự do, tạo điều kiện cho người dùng linh hoạt trong việc phát triển chiến lược giao dịch",
        p3: "Vị trí độc lập giảm rủi ro",
        p4: "Một loại tiền tệ có thể giữ vị trí với nhiều đòn bẩy khác nhau, mỗi vị trí phụ có thể điều chỉnh đòn bẩy một cách độc lập, tăng vị trí giảm, thiết lập điểm dừng lợi nhuận và hợp nhất không mất mát giữa các vị trí phụ, giúp người dùng giảm tác động từ lợi nhuận và lỗ tổng thể",
    },
    info4: {
        tit1: "Chức năng giao dịch III",
        tit2: "Đa nguyên ngăn chặn tổn thất",
        p1: "Ba chức năng dừng lỗ lớn",
        p2: "StartTrade cung cấp ba chế độ Stop-Earning Stop cho tất cả các vị trí, Stop-Earning Stop cho một số vị trí và Stop-Earning Stop cho giá kích hoạt để giúp người dùng kiểm soát rủi ro giao dịch",
        p3: "Tối đa 50 điểm dừng lỗ khác nhau",
        p4: "Người dùng có thể thiết lập điểm dừng doanh thu bất cứ lúc nào trong vị trí, lệnh chờ, linh hoạt trong việc kiểm soát rủi ro; Đồng thời hỗ trợ thiết lập tối đa 50 lệnh dừng lợi nhuận, bao gồm lệnh giới hạn hoặc lệnh thị trường, cho các số lượng và giá khác nhau của cùng một vị trí, đáp ứng các chiến lược đầu tư khác nhau của người dùng.",
    },
    info5: {
        p1: "Hoạt động ổn định 3 năm không ngừng nghỉ",
        p2: "Hỗ trợ kênh truyền thông công nghiệp",
        p3: "Đầu KOL hợp tác",
        p4: "Lựa chọn của hàng triệu người dùng",
    },
    why: {
        p1: "Tại sao chọn IDEX",
        p2: "Khả năng R&D độc lập mạnh mẽ",
        p3: "Tự phát triển hệ thống giao dịch thế hệ thứ ba từ 0 đến 1",
        p4: "Tạo trải nghiệm giao dịch tuyệt vời với hiệu suất vượt trội",
        p5: "Bảo mật hàng đầu",
        p6: "Kiểm soát rủi ro cô lập ví nóng và lạnh",
        p7: "Phương tiện kép để giữ tài sản của bạn an toàn",
        p8: "Hệ thống điều khiển gió hoàn hảo",
        p9: "Đôi giá mạnh mẽ phẳng bảo vệ cơ chế",
        p10: "Giảm thiểu rủi ro bùng nổ của người dùng",
        p11: "Bố cục toàn cầu hóa",
        p12: "Hội tụ Elite toàn cầu",
        p13: "Phân phối toàn cầu",
        p14: "7 * 24 giờ dịch vụ",
        p15: "Cơ chế phản hồi 1 phút",
        p16: "Phục vụ người dùng toàn cầu 24/7",
    },
    info6: {
        p1: "Bắt đầu giao dịch tiền kỹ thuật số?",
        p2: "Bắt đầu đầu tư cryptocurrency của bạn ngay bây giờ",
    },
    foot: {
        download: "Tải về",
        copy_success: "Sao chép thành công",
        copy_error: "Sao chép thất bại",
    },
    download: {
        h1: "请使用手机扫描二维码下载",
        copy: "复制",
        down: "下载",
        rate: "评分",
        age: "年龄",
        complain: "应用投诉",
        tit1: "新功能",
        version: "版本",
        p1: "此版本包括错误修复和性能改进",
        tit2: "应用介绍",
        p2: "欢迎来到 IDEX Global，世界领先的数字资产交易所之一！交易超过1100种加密货币，包括比特币(BTC)、以太坊(ETH)、狗狗币(DOGE)、MX Token(MX)等。作为世界领先的加密货币交易所，StartTrade以率先推出优质项目和模因币而闻名, StartTrade Global 提供专业、安全的数字资产交易和管理服务，受到全球超过 700 万用户的信赖。",
        tit3: "评论及评分",
        p3: "满分5分",
        tit4: "信息",
        label1: "供应商",
        label2: "大小",
        label3: "兼容性",
        label4: "语言",
        value1: "中文和英文",
        label5: "年龄",
        value2: "限18岁以上",
        label6: "价格",
        value3: "免费",
        tit5: "隐私政策",
        p5: "免责声明: 本网站仅为开发者提供App的下载和安装托管，App内的内容和运营相关事项由App开发者负责，与本网站无关。"
    }
};
