export default {
    home: {
        language: "Tukar Bahasa",
        banner_p1: "Penjualan pertukaran asing, komoditi, indeks, stok melalui platform",
        banner_p2: "",
        banner_msg1: "Dan produk kewangan yang lebih populer",
        banner_msg2: "",
        banner_msg3: "",
        banner_link1: "Daftar dan mula menggunakan sekarang",
        banner_link2: "Daftar Sekarang",
        swiper_h1: "Performance tinggi",
        swiper_h2: "Mesin yang sepadan",
        swiper_h3: "Aras keuangan",
        swiper_h4: "Perlindungan keselamatan",
        swiper_h5: "Produk",
        swiper_h6: "Rich and diverse",
        swiper_h7: "Globalisasi",
        swiper_h8: "Bentangan",
    },
    list: {
        title: "Popular",
        th1: "Titik",
        th2: "Harga terakhir",
        th3: "Perubahan 24 jam",
        th4: "Pasar",
        th5: "Untuk perdagangan",
    },
    info1: {
        tit1: "Produk utama platform",
        tit2: "Tempat kontrak biasa",
        p1: "Enjin pemadaman generasi ketiga",
        p2: "Sistem pemadaman kuat memastikan semua operasi utama pengguna selesai dalam 100 milisaat",
        p3: "Fungsi transaksi karakteristik dikembangkan secara independen",
        p4: "Fungsi perdagangan berfungsi seperti perintah luar talian, konsolidasi super, kehilangan berhenti berbeza dan menghentikan kehilangan, aras kuat harga ganda, dan satu klik balik, membantu perdagangan membuat keputusan yang lebih baik",
        p5: "Strategi perdagangan mudah",
        p6: "Sediakan pilihan penggunaan berbilang untuk memenuhi keutamaan risiko bagi pengedar pada semua aras",
        btn: "Mulakan perdagangan"
    },
    info2: {
        tit1: "Fungsi Transaksi 1",
        tit2: "Letak arahan pada garis K",
        p1: "Arahan tempatkan dengan cepat dengan satu klik",
        p2: "Perdagangan garis-K adalah set lengkap pengalaman fungsional inovatif yang dilancarkan secara eksklusif oleh StartTrade pada seluruh rangkaian. Berdasarkan fungsi perdagangan garis-K yang kuat, operasi perdagangan boleh dilakukan secara langsung pada halaman garis-K tanpa perlukan untuk bertukar balik dan balik diantara halaman pasar dan halaman perintah",
        p3: "Pengurusan transaksi komprensif",
        p4: "Pengguna boleh dengan mudah dan efisien menyelesaikan pelbagai operasi transaksi di garis K, termasuk operasi gudang dan operasi perintah. Benarkan pengguna untuk meletakkan perintah dengan cepat dan membuat pelarasan tepat pada setiap masa apabila pasar berubah dengan cepat",
    },
    info3: {
        tit1: "Fungsi Transaksi 2",
        tit2: "Pembahagian gudang super",
        p1: "Tukar bebas bagi kedudukan berbilang",
        p2: "Pengguna boleh memegang kedudukan bergabung dalam arah panjang dan pendek dalam wang yang sama, serta kedudukan sub berbilang dengan N lever berbeza, dan menyokong penggabungan bebas dan penggantian, membuat ia mudah bagi pengguna untuk membentuk strategi perdagangan fleksibel",
        p3: "Kedudukan independen mengurangi risiko",
        p4: "Setiap mata wang boleh memegang kedudukan berbilang dengan tahap lain leverage, dan setiap sub kedudukan boleh secara bebas menyesuaikan leverage, meningkatkan atau mengurangkan kedudukan, dan menetapkan penghentian keuntungan dan menghentikan kehilangan. Lagipun, sub kedudukan boleh bergabung tanpa apa-apa kehilangan, membantu pengguna mengurangkan kesan keuntungan dan kehilangan kedudukan keseluruhan",
    },
    info4: {
        tit1: "Fungsi Transaksi Tiga",
        tit2: "Bervariasi Hentikan Profit dan Hentikan Kehilangan",
        p1: "Tiga fungsi penghentian keuntungan dan kerugian besar",
        p2: "StartTrade menyediakan tiga mod: kehilangan berhenti kedudukan penuh, kehilangan berhenti kedudukan sebahagian, dan memicu kehilangan berhenti harga untuk membantu pengguna mengawal risiko perdagangan",
        p3: "Sehingga 50 kerugian berhenti berbeza dan berhenti kedudukan kehilangan",
        p4: "Pengguna boleh menetapkan penghentian keuntungan dan menghentikan titik kehilangan pada bila-bila masa dalam kedudukan dan arahan mereka, mengawal risiko fleksibel; Pada masa yang sama, ia menyokong menetapkan sehingga 50 perintah kehilangan hentian, termasuk perintah had atau perintah pasar, untuk lembaran dan harga yang berbeza dalam kedudukan yang sama, untuk memenuhi strategi pelaburan yang berbeza pengguna",
    },
    info5: {
        p1: "Operasi stabil selama 3 tahun tanpa masa turun atau lambat",
        p2: "Sokongan saluran media industri",
        p3: "Kooperasi Kepala KOL",
        p4: "Pilihan jutaan Pengguna",
    },
    why: {
        p1: "Mengapa pilih IDEX",
        p2: "Kemampuan penelitian dan pembangunan independen yang kuat",
        p3: "Pembangunan secara independen sistem perdagangan generasi ketiga dari 0 ke 1",
        p4: "Mencipta Pengalaman Penjualan Terutama dengan Performance Excellent",
        p5: "Keselamatan aras atas",
        p6: "Kawalan risiko untuk pengasingan dompet panas dan sejuk",
        p7: "Langkah dua untuk memastikan keselamatan aset anda",
        p8: "Sistem kawalan risiko yang meliputi",
        p9: "Mekanisme perlindungan persamaan kuat harga ganda",
        p10: "Kurangkan risiko eksposisi pengguna",
        p11: "Bentangan global",
        p12: "Pengumpulan Elite Global",
        p13: "Penghapusan pasukan global",
        p14: "7 * 24-jam perkhidmatan",
        p15: "Mekanisme balas 1-minit",
        p16: "Perkhidmatan 24/7 untuk pengguna global",
    },
    info6: {
        p1: "Mula bertukar wang digital?",
        p2: "Mulakan pelaburan kriptocurrency anda sekarang",
    },
    foot: {
        download: "Muat turun",
        copy_success: "Salin berjaya",
        copy_error: "Salinan gagal",
    },
    download: {
        h1: "请使用手机扫描二维码下载",
        copy: "复制",
        down: "下载",
        rate: "评分",
        age: "年龄",
        complain: "应用投诉",
        tit1: "新功能",
        version: "版本",
        p1: "此版本包括错误修复和性能改进",
        tit2: "应用介绍",
        p2: "欢迎来到 IDEX Global，世界领先的数字资产交易所之一！交易超过1100种加密货币，包括比特币(BTC)、以太坊(ETH)、狗狗币(DOGE)、MX Token(MX)等。作为世界领先的加密货币交易所，StartTrade以率先推出优质项目和模因币而闻名, StartTrade Global 提供专业、安全的数字资产交易和管理服务，受到全球超过 700 万用户的信赖。",
        tit3: "评论及评分",
        p3: "满分5分",
        tit4: "信息",
        label1: "供应商",
        label2: "大小",
        label3: "兼容性",
        label4: "语言",
        value1: "中文和英文",
        label5: "年龄",
        value2: "限18岁以上",
        label6: "价格",
        value3: "免费",
        tit5: "隐私政策",
        p5: "免责声明: 本网站仅为开发者提供App的下载和安装托管，App内的内容和运营相关事项由App开发者负责，与本网站无关。"
    }
};
