export default {
    home: {
        language: "語言切換",
        banner_p1: "通過平臺交易外匯、商品、指數、股票",
        banner_p2: "",
        banner_msg1: "和更多熱門金融產品",
        banner_msg2: "",
        banner_msg3: "",
        banner_link1: "立即注册並開始使用",
        banner_link2: "立即注册",
        swiper_h1: "高性能",
        swiper_h2: "撮合引擎",
        swiper_h3: "金融級",
        swiper_h4: "安全防護",
        swiper_h5: "產品",
        swiper_h6: "豐富多樣",
        swiper_h7: "全球化",
        swiper_h8: "佈局",
    },
    list: {
        title: "熱門",
        th1: "現貨",
        th2: "最新價",
        th3: "24H漲跌幅",
        th4: "行情",
        th5: "去交易",
    },
    info1: {
        tit1: "平臺覈心產品",
        tit2: "永續合約現貨",
        p1: "第三代撮合引擎",
        p2: "强大的撮合系統，保證用戶所有覈心操作100毫秒內完成",
        p3: "自主研發特色交易功能",
        p4: "K線下單、超級和倉、多元止盈止損、雙價格强平、一鍵反轉等特色交易功能，幫助交易者做出更好的決策",
        p5: "靈活的交易策略",
        p6: "提供多種杠杆選擇，滿足各級別交易者的風險偏好",
        btn: "開始交易"
    },
    info2: {
        tit1: "交易功能一",
        tit2: "K線下單",
        p1: "一鍵極速下單",
        p2: "K線交易是StartTrade全網獨家推出的一整套創新功能體驗，基於强大的K線交易功能，直接在K線頁面進行交易操作，無需在行情頁和下單頁之間來回切換",
        p3: "全方位交易管理",
        p4: "用戶可以非常直觀高效的在k線上完成各種交易操作，包括：倉位操作、訂單操作。 讓用戶在行情快速變化的時候，可以快速下單，隨時精准調整",
    },
    info3: {
        tit1: "交易功能二",
        tit2: "超級分倉",
        p1: "多種倉位自由切換",
        p2: "用戶在同一幣種下可同時持有多空兩個方向的合倉倉位，又可以持有N個不同杠杆的多個分倉倉位，並支持自由合併切換，方便用戶靈活製定交易策略",
        p3: "獨立倉位降低風險",
        p4: "一幣種可持有多個不同杠杆的倉位，每個分倉都能獨立調整杠杆、加倉減倉、設定止盈止損，且分倉之間可隨意無損合併，幫助用戶降低來自整體倉位盈虧的影響",
    },
    info4: {
        tit1: "交易功能三",
        tit2: "多元止盈止損",
        p1: "三大止盈止損功能",
        p2: "StartTrade提供全部倉位止盈止損、部分倉位止盈止損、觸發價止盈止損三種模式，幫助用戶控制交易風險",
        p3: "最多50個不同的止盈止損點比特",
        p4: "用戶可以在倉位、掛單中隨時設定止盈止損點比特，靈活控制風險； 同時支持針對同一倉位不同張數和價格設定多達50個止盈止損單，包括限價單或市價單，滿足用戶不同投資策略",
    },
    info5: {
        p1: "穩定運營3年無宕機卡頓",
        p2: "行業媒體通路支持",
        p3: "頭部KOL合作",
        p4: "百萬用戶的選擇",
    },
    why: {
        p1: "為什麼選擇IDEX",
        p2: "强大自主研發能力",
        p3: "從0到1自主研發第三代交易系統",
        p4: "打造卓越性能下的極致交易體驗",
        p5: "頂級安全保障",
        p6: "冷熱錢包隔離風險控制",
        p7: "雙重手段保障您的資產安全",
        p8: "完善的風控體系",
        p9: "雙價格强平保護機制",
        p10: "降低用戶爆倉風險",
        p11: "全球化佈局",
        p12: "全球精英彙聚",
        p13: "團隊分佈全球",
        p14: "7*24小時服務",
        p15: "1分鐘響應機制",
        p16: "全天候服務全球用戶",
    },
    info6: {
        p1: "開始交易數位貨幣？",
        p2: "立刻開啟你的加密貨幣投資",
    },
    foot: {
        download: "下載",
        copy_success: "複製成功",
        copy_error: "複製失敗",
    },
    download: {
        h1: "请使用手机扫描二维码下载",
        copy: "复制",
        down: "下载",
        rate: "评分",
        age: "年龄",
        complain: "应用投诉",
        tit1: "新功能",
        version: "版本",
        p1: "此版本包括错误修复和性能改进",
        tit2: "应用介绍",
        p2: "欢迎来到 IDEX Global，世界领先的数字资产交易所之一！交易超过1100种加密货币，包括比特币(BTC)、以太坊(ETH)、狗狗币(DOGE)、MX Token(MX)等。作为世界领先的加密货币交易所，StartTrade以率先推出优质项目和模因币而闻名, StartTrade Global 提供专业、安全的数字资产交易和管理服务，受到全球超过 700 万用户的信赖。",
        tit3: "评论及评分",
        p3: "满分5分",
        tit4: "信息",
        label1: "供应商",
        label2: "大小",
        label3: "兼容性",
        label4: "语言",
        value1: "中文和英文",
        label5: "年龄",
        value2: "限18岁以上",
        label6: "价格",
        value3: "免费",
        tit5: "隐私政策",
        p5: "免责声明: 本网站仅为开发者提供App的下载和安装托管，App内的内容和运营相关事项由App开发者负责，与本网站无关。"
    }
};
