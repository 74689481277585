export default {
    home: {
        language: "การสลับภาษา",
        banner_p1: "เทรดฟอเร็กซ์, สินค้าโภคภัณฑ์, ดัชนี, หุ้นผ่านแพลตฟอร์ม",
        banner_p2: "",
        banner_msg1: "และผลิตภัณฑ์ทางการเงินยอดนิยมอีกมากมาย",
        banner_msg2: "",
        banner_msg3: "",
        banner_link1: "ลงทะเบียนตอนนี้ และเริ่มใช้งาน",
        banner_link2: "ลงทะเบียนตอนนี้",
        swiper_h1: "ประสิทธิภาพสูง",
        swiper_h2: "การจับคู่เครื่องยนต์",
        swiper_h3: "ระดับการเงิน",
        swiper_h4: "การป้องกันความปลอดภัย",
        swiper_h5: "ผลิตภัณฑ์",
        swiper_h6: "อุดมไปด้วยความหลากหลาย",
        swiper_h7: "โลกาภิวัตน์",
        swiper_h8: "การจัดวาง",
    },
    list: {
        title: "ร้อน",
        th1: "ในสต็อก",
        th2: "ราคาล่าสุด",
        th3: "24H ขึ้นและลง",
        th4: "สภาวะตลาด",
        th5: "ไปแลกเปลี่ยน",
    },
    info1: {
        tit1: "ผลิตภัณฑ์หลักของแพลตฟอร์ม",
        tit2: "สัญญาถาวร ในสต็อก",
        p1: "เครื่องยนต์จับคู่รุ่นที่สาม",
        p2: "ระบบจับคู่ที่มีประสิทธิภาพซึ่งรับประกันการทำงานหลักทั้งหมดของผู้ใช้ภายใน 100 มิลลิวินาที",
        p3: "การวิจัยและพัฒนาตนเองคุณลักษณะการซื้อขายที่โดดเด่น",
        p4: "สั่งซื้อสินค้า K-Line、ซุปเปอร์วาคุระ、หยุดการทำกำไรหลาย、ราคาคู่ที่แข็งแกร่ง、คุณสมบัติการเทรดที่โดดเด่น เช่น ，ช่วยให้เทรดเดอร์ตัดสินใจได้ดีขึ้น",
        p5: "กลยุทธ์การเทรดที่ยืดหยุ่น",
        p6: "เสนอทางเลือกเลเวอเรจที่หลากหลายเพื่อให้เหมาะกับความเสี่ยงที่ยอมรับได้ของเทรดเดอร์ทุกระดับ",
        btn: "เริ่มต้นเทรด"
    },
    info2: {
        tit1: "คุณสมบัติการซื้อขายหนึ่ง",
        tit2: "สั่งซื้อสินค้า K-Line",
        p1: "สั่งซื้อด้วยความเร็วสูงด้วยปุ่มเดียว",
        p2: "การซื้อขาย K-Line เป็นชุดของประสบการณ์การทำงานที่เป็นนวัตกรรมใหม่ที่เปิดตัวโดย StartTrade Full Net บนพื้นฐานของคุณสมบัติการซื้อขาย K-Line ที่มีประสิทธิภาพการดำเนินการซื้อขายโดยตรงบนหน้า K-Line โดยไม่ต้องสลับไปมาระหว่างหน้าตลาดและหน้าสั่งซื้อ",
        p3: "การจัดการธุรกรรมเต็มรูปแบบ",
        p4: "ผู้ใช้สามารถดำเนินการซื้อขายต่างๆบน kline ได้อย่างง่ายดายและมีประสิทธิภาพมากรวมถึง: การดำเนินการตำแหน่ง, การดำเนินการสั่งซื้อ ให้ผู้ใช้เมื่อตลาดเปลี่ยนแปลงอย่างรวดเร็วสามารถสั่งซื้อได้อย่างรวดเร็วและปรับได้อย่างแม่นยำตลอดเวลา",
    },
    info3: {
        tit1: "คุณสมบัติการซื้อขายสอง",
        tit2: "ซุปเปอร์แยกไซโล",
        p1: "การสลับสถานะต่างๆได้อย่างอิสระ",
        p2: "ผู้ใช้สามารถถือตำแหน่งปิดสองทิศทางของหลายช่องพร้อมกันในสกุลเงินเดียวกันและยังสามารถถือตำแหน่งแยกหลายทิศทางด้วย N เลเวอเรจที่แตกต่างกันและรองรับการรวมและการสลับฟรีช่วยให้ผู้ใช้มีความยืดหยุ่นในการพัฒนากลยุทธ์การซื้อขาย",
        p3: "ตำแหน่งอิสระลดความเสี่ยง",
        p4: "สกุลเงินหนึ่งสามารถถือครองหลายตำแหน่งที่มีเลเวอเรจที่แตกต่างกันและแต่ละตำแหน่งสามารถปรับเลเวอเรจได้อย่างอิสระเพิ่มตำแหน่งและปิดตำแหน่งตั้งค่า Stop Profit Stop และระหว่าง Stop สามารถรวมแบบไม่สูญเสียได้ตามต้องการ ช่วยให้ผู้ใช้ลดผลกระทบจากกำไรและขาดทุนของตำแหน่งโดยรวม",
    },
    info4: {
        tit1: "คุณสมบัติการเทรด III",
        tit2: "หยุดการทำกำไรหลาย",
        p1: "สามคุณสมบัติหยุดกำไรและหยุดขาดทุน",
        p2: "StartTrade มีสามโหมดสำหรับตำแหน่งทั้งหมด Stop Profit Stop, Stop Profit สำหรับตำแหน่งบางส่วน, Trigger Price Stop Profit Stop Stop เพื่อช่วยให้ผู้ใช้สามารถควบคุมความเสี่ยงในการซื้อขายได้",
        p3: "สูงสุด 50 จุด Stop Loss ที่แตกต่างกัน",
        p4: "ผู้ใช้สามารถตั้งค่า Stop Profit Stop Point ในตำแหน่งคำสั่งซื้อขายได้ตลอดเวลาเพื่อควบคุมความเสี่ยงได้อย่างยืดหยุ่น รองรับการตั้งค่า Stop Loss ได้สูงสุด 50 รายการสำหรับจำนวนแผ่นและราคาที่ต่างกันในตำแหน่งเดียวกัน รวมทั้ง Limit Order หรือ Price Order เพื่อตอบสนองกลยุทธ์การลงทุนที่แตกต่างกันของผู้ใช้",
    },
    info5: {
        p1: "การดำเนินงานที่มั่นคง 3 ปีไม่มีการหยุดทำงาน Caton",
        p2: "การสนับสนุนช่องทางสื่ออุตสาหกรรม",
        p3: "ความร่วมมือหัวหน้า KOL",
        p4: "ทางเลือกของผู้ใช้นับล้าน",
    },
    why: {
        p1: "ทำไมต้องเลือก IDEX",
        p2: "ความสามารถในการวิจัยและพัฒนาตนเองที่แข็งแกร่ง",
        p3: "พัฒนาระบบการซื้อขายรุ่นที่สามด้วยตนเองจาก 0 ถึง 1",
        p4: "สร้างประสบการณ์การเทรดสูงสุดด้วยประสิทธิภาพที่ยอดเยี่ยม",
        p5: "รับประกันความปลอดภัยสูงสุด",
        p6: "กระเป๋าสตางค์ร้อนและเย็นแยกการควบคุมความเสี่ยง",
        p7: "การรักษาความปลอดภัยสินทรัพย์ของคุณด้วยสองวิธี",
        p8: "ระบบควบคุมลมที่สมบูรณ์แบบ",
        p9: "กลไกการป้องกันที่แข็งแกร่งและราคาคู่",
        p10: "ลดความเสี่ยงของผู้ใช้งาน",
        p11: "รูปแบบโลกาภิวัตน์",
        p12: "การรวมตัวของชนชั้นสูงทั่วโลก",
        p13: "การกระจายทีมทั่วโลก",
        p14: "บริการ 7 * 24 ชั่วโมง",
        p15: "กลไกการตอบสนอง 1 นาที",
        p16: "บริการ 24/7 สำหรับผู้ใช้ทั่วโลก",
    },
    info6: {
        p1: "เริ่มต้นเทรดสกุลเงินดิจิตอล?",
        p2: "เปิดการลงทุน cryptocurrency ของคุณทันที",
    },
    foot: {
        download: "ดาวน์โหลด",
        copy_success: "คัดลอกสำเร็จ",
        copy_error: "การคัดลอกล้มเหลว",
    },
    download: {
        h1: "请使用手机扫描二维码下载",
        copy: "复制",
        down: "下载",
        rate: "评分",
        age: "年龄",
        complain: "应用投诉",
        tit1: "新功能",
        version: "版本",
        p1: "此版本包括错误修复和性能改进",
        tit2: "应用介绍",
        p2: "欢迎来到 IDEX Global，世界领先的数字资产交易所之一！交易超过1100种加密货币，包括比特币(BTC)、以太坊(ETH)、狗狗币(DOGE)、MX Token(MX)等。作为世界领先的加密货币交易所，StartTrade以率先推出优质项目和模因币而闻名, StartTrade Global 提供专业、安全的数字资产交易和管理服务，受到全球超过 700 万用户的信赖。",
        tit3: "评论及评分",
        p3: "满分5分",
        tit4: "信息",
        label1: "供应商",
        label2: "大小",
        label3: "兼容性",
        label4: "语言",
        value1: "中文和英文",
        label5: "年龄",
        value2: "限18岁以上",
        label6: "价格",
        value3: "免费",
        tit5: "隐私政策",
        p5: "免责声明: 本网站仅为开发者提供App的下载和安装托管，App内的内容和运营相关事项由App开发者负责，与本网站无关。"
    }
};
