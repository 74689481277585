export default {
    home: {
        language: "복제 실패",
        banner_p1: "플랫폼을 통해 외환, 상품, 지수, 주식을 거래하다",
        banner_p2: "",
        banner_msg1: "및 더 많은 인기 금융 상품",
        banner_msg2: "",
        banner_msg3: "",
        banner_link1: "지금 등록 및 사용 시작",
        banner_link2: "지금 등록",
        swiper_h1: "고성능",
        swiper_h2: "중매 엔진",
        swiper_h3: "금융급",
        swiper_h4: "보안",
        swiper_h5: "제품",
        swiper_h6: "풍부하고 다양하다",
        swiper_h7: "세계화",
        swiper_h8: "레이아웃",
    },
    list: {
        title: "인기",
        th1: "현물",
        th2: "최신 가격",
        th3: "24H 상승 폭",
        th4: "시세",
        th5: "거래하러 가다",
    },
    info1: {
        tit1: "플랫폼 핵심 제품",
        tit2: "영구 계약 현물",
        p1: "3세대 중매 엔진",
        p2: "사용자의 모든 핵심 작업을 100밀리초 이내에 완료할 수 있는 강력한 중매 시스템",
        p3: "특색 있는 거래 기능을 자체 연구 개발하다.",
        p4: "K선 주문, 슈퍼 및 창고, 다원 수익 및 손실 방지, 이중 가격 강평, 원클릭 반전 등 특색 있는 거래 기능은 거래자가 더 나은 결정을 내릴 수 있도록 돕는다",
        p5: "유연한 거래 전략",
        p6: "다양한 레벨 트레이더의 위험 선호도를 충족하는 다양한 레버리지 옵션 제공",
        btn: "거래 시작"
    },
    info2: {
        tit1: "거래 기능 1",
        tit2: "K 오프라인 주문",
        p1: "원클릭 빠른 주문",
        p2: "K선 거래는 StartTrade 전체 네트워크가 독점적으로 출시한 일련의 혁신적인 기능 체험으로, 강력한 K선 거래 기능을 기반으로 K선 페이지에서 직접 거래 조작을 진행하며, 시세 페이지와 주문 페이지 사이를 왔다갔다할 필요가 없다",
        p3: "포괄적인 거래 관리",
        p4: "사용자는 매우 직관적이고 효율적으로 k라인에서 각종 거래 조작을 완성할 수 있는데 창고 조작, 주문 조작을 포함한다.사용자가 시세가 빠르게 변화할 때, 빠르게 주문할 수 있고, 수시로 정확하게 조정할 수 있도록 한다",
    },
    info3: {
        tit1: "거래 기능 2",
        tit2: "슈퍼 분할 창고",
        p1: "다양한 창고 위치 자유 전환",
        p2: "사용자는 동일한 화폐 하에서 동시에 다공 두 방향의 합창 창고를 보유할 수 있고, 또 N개의 서로 다른 레버리지의 여러 분창 창고를 보유할 수 있으며, 자유 합병 전환을 지원하여 사용자가 거래 전략을 유연하게 제정할 수 있도록 편리하게 한다",
        p3: "독립창고로 위험을 줄이다.",
        p4: "한 화폐는 여러 개의 서로 다른 레버리지의 창고를 보유할 수 있으며, 각 창고는 독립적으로 레버리지를 조정하고, 창고를 추가하고 창고를 줄이며, 수익 정지와 손실 정지를 설치할 수 있으며, 창고 간에 마음대로 손실 없이 합병할 수 있어 사용자가 전체 창고의 손익에서 오는 영향을 줄일 수 있도록 돕는다",
    },
    info4: {
        tit1: "거래 기능 3",
        tit2: "다원적 으로 이익 을 멈추고 손실 을 막다",
        p1: "3대 손익 방지 기능",
        p2: "StartTrade는 모든 창고 흑자 방지, 일부 창고 흑자 방지, 촉발 가격 흑자 방지 등 세 가지 모델을 제공하여 사용자가 거래 위험을 통제할 수 있도록 돕는다",
        p3: "최대 50개의 서로 다른 손실 방지 지점",
        p4: "사용자는 창고, 등기 명세서에 수시로 수익 정지 및 손실 방지 지점을 설치하여 위험을 유연하게 통제할 수 있다;동시에 동일한 창고의 서로 다른 장수와 가격에 대해 최대 50개의 수익 제한 및 손실 방지 주문서를 설정하는 것을 지원하며, 가격 제한 주문서 또는 시가 주문서를 포함하여 사용자의 서로 다른 투자 전략을 만족시킨다",
    },
    info5: {
        p1: "3년 무중단 업무 운영",
        p2: "업계 미디어 채널 지원",
        p3: "헤드 KOL 협력",
        p4: "백만 사용자 선택",
    },
    why: {
        p1: "IDEX를 선택해야 하는 이유",
        p2: "강력한 자체 개발 능력",
        p3: "0부터 1까지 3세대 거래 시스템 자체 개발",
        p4: "뛰어난 성능의 트랜잭션 환경 구축",
        p5: "최고 수준의 보안",
        p6: "냉열 지갑 격리 위험 제어",
        p7: "자산을 안전하게 보호하는 이중 수단",
        p8: "완벽한 풍력 통제 체계",
        p9: "이중 가격 강평 보호 메커니즘",
        p10: "사용자 창고 폭발 위험 감소",
        p11: "글로벌 레이아웃",
        p12: "글로벌 엘리트 집결",
        p13: "전 세계에 팀 배포",
        p14: "7*24시간 서비스",
        p15: "1분 응답 메커니즘",
        p16: "전 세계 사용자를 위한 연중무휴 24시간 서비스",
    },
    info6: {
        p1: "디지털 화폐 거래 시작?",
        p2: "지금 암호화폐 투자를 시작하세요",
    },
    foot: {
        download: "다운로드",
        copy_success: "복제 성공",
        copy_error: "복제 실패",
    },
    download: {
        h1: "请使用手机扫描二维码下载",
        copy: "复制",
        down: "下载",
        rate: "评分",
        age: "年龄",
        complain: "应用投诉",
        tit1: "新功能",
        version: "版本",
        p1: "此版本包括错误修复和性能改进",
        tit2: "应用介绍",
        p2: "欢迎来到 IDEX Global，世界领先的数字资产交易所之一！交易超过1100种加密货币，包括比特币(BTC)、以太坊(ETH)、狗狗币(DOGE)、MX Token(MX)等。作为世界领先的加密货币交易所，StartTrade以率先推出优质项目和模因币而闻名, StartTrade Global 提供专业、安全的数字资产交易和管理服务，受到全球超过 700 万用户的信赖。",
        tit3: "评论及评分",
        p3: "满分5分",
        tit4: "信息",
        label1: "供应商",
        label2: "大小",
        label3: "兼容性",
        label4: "语言",
        value1: "中文和英文",
        label5: "年龄",
        value2: "限18岁以上",
        label6: "价格",
        value3: "免费",
        tit5: "隐私政策",
        p5: "免责声明: 本网站仅为开发者提供App的下载和安装托管，App内的内容和运营相关事项由App开发者负责，与本网站无关。"
    }
};
